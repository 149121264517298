/**
 * コモンクラス
 * @constructor
 */
class Common {
  constructor() {

  }
  /**
   * コモン
   */
  init() {
    $(function() {
      var header = $('.js-header');
      var footer = $('.js-footer');
      var pageTop = $('.js-pageTop');
      $(window).on('load scroll resize', function() {
        if($(window).scrollTop() > 20) {
          header.addClass('is-scroll');
        } else {
          header.removeClass('is-scroll');
        }
        if($(window).scrollTop() > 400) {
          pageTop.addClass('is-fixed');
          if($(window).scrollTop() > footer.offset().top - $(window).height()){
            pageTop.addClass('is-absolute');
            pageTop.removeClass('is-fixed');
          } else {
            pageTop.addClass('is-fixed');
            pageTop.removeClass('is-absolute');
          }
        } else {
          pageTop.removeClass('is-fixed');
        }
      });
    });
  }

}

export default Common;
